@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-dark-background text-dark-text;
}

#root {
  @apply bg-dark-background text-dark-text;
}

/* Your other custom styles go here */